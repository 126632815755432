@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/*
.App {
  text-align: center;
}
*/

.Centered {
    text-align: center;
}

.Session-info {
  display: inline;
  font-family: 'Open Sans', Courier, monospace;
  font-size: .7em;
  font-weight: 700;
  float: right;
  padding-top: 0.3em;
}

.Ws-logo {
  width: 50%;
  margin: 2rem auto;
  pointer-events: none;
}

.Client-logo {
  width: 90%;
  margin: 2rem auto;
  pointer-events: none;
}

.Login-form {
  text-align: center;
  padding: 1rem;
}

.Page-loading > div {
  padding-top: 20vh;
  margin: auto;
  zoom: 3;
}

.Subpage-loading {
  padding: 2vh;
  text-align: center;
  zoom: 3;
}

.notification-container {
  padding: 0 30%;
  width: 100%;
}

.Img-fullpage {
  display: block;
  margin: auto;
}

.highlighted {
  background-color: yellow
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Titlebar {
  background: #FAFAFA;  
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  font-weight: bold;
  font-size: 1.8rem;
  width: 100%;
  border-bottom: 1px solid #999;
  display: block;
  padding: .5em 1em;
}

.Page {
  text-align: left;
  padding: 1rem 1rem 1rem 1rem;    
  width: 100%;
}

.Navbar {
  float: left;
  z-index: 1;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  width: 14rem;
  font-size: 1.5rem;
  background-color: white;
  border-right: 1px solid #999;
}

.NavbarCollapsed {
    float: left;
    padding: 0 2px 0 2px;
    position: fixed;
    min-height: 100vh;
    z-index: 1;
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
    width: 1rem;
    font-size: 1.5rem;
    background-color: white;
}

.Navigation-link {
  display: block;
  text-decoration: none !important;
  color: #333;  
}

.NavBottomBorder {
  border-bottom: 1px solid #999;
}

.NavigationLinkNormal {
  font-size: 1.2rem;
  padding: 1.2rem .5rem;
}
.NavigationLinkCompressed {
  font-size: 0.6em;
  padding: 0.1rem .5rem;
}

.Navigation-link:nth-of-type(1) {
  border-top: 1px solid #999;
}

.Navigation-link.selected,
.Navigation-link:hover {
  cursor: pointer;
  background: #ecf1ff;  
  transition: background-color 0.5s linear;
}

.Beta-tag {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.6em;
  color: white;
  position: absolute;
  display: ruby;
  border-radius: .1em;
  padding: 0em 0.5em;
  background-color: green;
  transform: rotate(20deg);
  z-index: 1;
}

.Logout {
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: white;
  /* position: fixed;
  bottom: 2rem;
  left: 3rem;
  width: 6rem; */
}

.External-backdrop {
  width: 100vw;
  height: 100vh;
  background-image: url("assets/images/spikes.png");
}

.Fadein-wrapper {
  opacity: 0;
}
.Fadein-wrapper.load {
  opacity: 1;
  transition: opacity 1s linear;
}

.Risk-wrapper {
  margin: 1em 0em;
}

.Risk-wrapper .Risk-section {
  vertical-align: text-bottom;
}

.Risk-wrapper .Risk-section div {
  padding: 0.5em 1em;
}

.Risk-wrapper .Risk-section .title {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  display: inline-block;
  text-align: right;
  margin-top: -0.2em
}

.Risk-wrapper .Risk-section .content {
  display: inline-block;
}

.page-notice {
  text-align: center;
  font-size: 2rem;
}

.modal-footer {
  display: block;
  text-align: center;
}

.Above1 {
    margin-top: 1px;
}
.Above2 {
  margin-top: 2px;
}
.Above12 {
    margin-top: 12px;
}
.Below12 {
    margin-bottom: 12px;
}
.MyIcon {
    color:blue;
}
.MyIcon:hover {
    background-color: lightskyblue;
}
.MyLink {
    color:blue
}
.MyLink:hover {
    cursor: pointer;
    text-decoration: underline;
}
.IconLink, .LightGray 
{
    color: lightgray;
}
.IconLink:hover
{
    color: black;
}
.FloatRight {
    float: right;
}
.FloatLeft {
    float: left;
}
.ClearBoth {
    clear: both;
}
.DlgSubTitle, .MySubTitle {
    font-size: small;
    font-weight: bold;
    font-variant: small-caps;
}
.FullWidth {
    width: 100%;
}

.SmallText {
  font-size: small;
}
.NavTiny, .XSmallText {
    font-size: x-small;
}
.RiskGridSingleLine .rdt_TableRow {
    cursor: pointer;
}
.RiskGridSingleLine .rdt_TableRow:hover {
    background-color: blanchedalmond;
}
.WarningText
{
    color: darkred;
    font-weight: bold;
}
.SuccessText
{
    color: green;
    font-weight: bold;
}
.LogoContainer {
    padding: 5px;
}

.TVTitle {
  color: darkgreen;
  padding: 3px 0 3px 0;
}
.TVPre {
  border: solid 1px darkgreen;
  background-color: lightgreen;
  font-size: small;
}
.TVPane {
  border-left: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
  padding: 6px;
  margin-top: 0px;
  display: inline-grid;
}

.page-layout {
display: flex;
}

.page-wrapper {
  width: 100vw;
}

.WarningBanner {
  text-align: center;
  border: solid 2px red;
  border-radius: 6px;
  background-color: pink;
  font-size: large;
  padding: 10px;
  margin: 12px 0 12px 0;
}

.RightAlign {
  text-align: right;
}

.BorderedTab {
  padding: 12px;
  border-left: solid 1px lightgray;
  border-right: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
}

.SubtleBackground0 {
  background-color: #ddd;
}
.SubtleBackground1 {
  background-color: #eee;
}